body {
  font-family: 'Roboto', sans-serif;
  color: #c4a780;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #070604;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

header {
  background-color: #070604;
  color: #c4a780;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

header .logo-container {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  animation: moveAndRotate 20s linear infinite;
}

header .logo-container img {
  width: 100%;
  height: auto;
  transition: transform 0.5s;
}

@keyframes moveAndRotate {
  0% {
    left: 0;
    transform: rotate(90deg);
  }
  45% {
    left: calc(100vw - 50px);
    transform: rotate(90deg);
  }
  50% {
    left: calc(100vw - 50px);
    transform: rotate(270deg);
  }
  95% {
    left: 0;
    transform: rotate(270deg);
  }
  100% {
    left: 0;
    transform: rotate(90deg);
  }
}

nav {
  margin-top: 3rem;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin-right: 1rem;
}

nav a {
  color: #c4a780;
  text-decoration: none;
}

main {
  padding: 2rem 0;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.post {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.post h2 a {
  color: #c4a780;
  text-decoration: none;
}

.post h2 a:hover {
  text-decoration: underline;
}

footer {
  background-color: #070604;
  color: #c4a780;
  padding: 1rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.linkedin-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkedin-icon {
  width: 24px;
  height: 24px;
}

.social-icons-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  width: 2rem; /* Adjust the size as needed */
  height: 2rem; /* Adjust the size as needed */
  margin: 0 8px;
}

.contact-info {
  margin-top: 20px;
}
.contact-info a {
  color: inherit; /* This makes the link color the same as the text color */
  text-decoration: none;
}
.contact-info a:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

a {
  color: inherit; /* Inherit color from the surrounding text */
  text-decoration: underline; /* Add underline to links */
}